<template>
  <b-card
    class="produceordercomplete-edit-wrapper"
  >
    <!-- form -->
    <b-form id="produceordercompleteForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购订单ID"
            label-for="produce_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="produce_id"
              size="sm"
              v-model="produceordercomplete.produce_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产单编号"
            label-for="produce_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="produce_no"
              size="sm"
              v-model="produceordercomplete.produce_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="produceordercomplete.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="produceordercomplete.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品规格"
            label-for="specification"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="specification"
              size="sm"
              v-model="produceordercomplete.specification"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="基本单位 product_unit"
            label-for="product_unit"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_unit"
              size="sm"
              v-model="produceordercomplete.product_unit"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="单箱标准数量"
            label-for="box_quantity"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="box_quantity"
              size="sm"
              v-model="produceordercomplete.box_quantity"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="成本价  含税单价"
            label-for="cost_price"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cost_price"
              size="sm"
              v-model="produceordercomplete.cost_price"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="produceordercomplete.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="produceordercomplete.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="produceordercomplete.modify_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import produceordercompleteStore from './produceordercompleteStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      produceordercomplete: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('produceordercomplete')) store.registerModule('produceordercomplete', produceordercompleteStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('produceordercomplete')) store.unregisterModule('produceordercomplete')
    })

    const edit = function() {
      store.dispatch('produceordercomplete/edit', {id: this.id}).then(res => {
        this.produceordercomplete = res.data.data
      })
    }

    const view = function() {
      store.dispatch('produceordercomplete/view', {id: this.id}).then(res => {
        this.produceordercomplete = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('produceordercomplete/save', this.produceordercomplete).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>